<template>
  <div v-if="mode === 'view'" class="fr-tags" :class="{ edit: mode === 'edit' }">
    <div class="title">
      <img src="@/assets/icons/tag-yellow.svg" alt="">
      <span>{{ $t('ai_tag')/*人物標記*/ }}</span>
    </div>
    <div class="content">
      <div v-for="tag in selectedTags" :key="tag" class="tag" :class="{ locked: isLocked(tag) }">
        {{ getTagNameById(tag) }}
      </div>
    </div>
  </div>
  <div v-else class="fr-tags-edit" :class="{ disabled: currDefaultSetting === 1 || isEditRoi }">
    <div v-if="currDefaultSetting === 1" class="disabled-mask"></div>
    <div class="title">
      <img src="@/assets/icons/tag-yellow.svg" alt="">
      <span>{{ $t('ai_tag')/*人物標記*/ }}</span>
    </div>
    <div class="content">
      <div class="tags">
        <div v-for="(tag, index) in selectedTags" :key="tag" class="tag">
          
          <div class="icon" @click="removeTag(index)">
            <img src="@/assets/icons/TrashCan.svg" alt="">
          </div>
          <div v-if="isLocked(tag)" class="square"></div>
          <span>{{ getTagNameById(tag) }}</span>
        </div>
      </div>
      <div class="add-tag" @click="handleTagOptions">
        <img src="@/assets/icons/plus.svg" alt="">
      </div>
    </div>
    <div v-if="showTagOptions" class="tag-options" v-click-outside="onClickOutside">
      <div v-for="tag in tagOptions" :key="tag.id" class="tag" @click="addTag(tag.id)">
        <div v-if="isLocked(tag.id)" class="locked"></div>
        {{ tag.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import vClickOutside from 'v-click-outside'

export default {
  name: 'FrTags',
  data() {
    return {
      showTagOptions: false,
    }
  },
  computed: {
    ...mapState(['frTagList']),
    ...mapState('aiboxFr', ['mode', 'currDefaultSetting', 'isEditRoi']),
    ...mapGetters('aiboxFr', ['currentSetting']),
    selectedTags: {
      get() {
        return this.currentSetting.setting.deviceConfig.fr.tagFilter
      },
      set(value) {
        this.updateTagFilter(value)
      }
    },
    // tagOptions 為不包含已選擇的標籤tagList的標籤
    tagOptions() {
      return this.frTagList.filter(tag => !this.selectedTags.includes(tag.id))
    }  
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    ...mapMutations('aiboxFr', ['updateTagFilter']),
    // 從id 取得標籤名稱
    getTagNameById(id) {
      return this.frTagList.find(tag => tag.id === id).name
    },
    isLocked(id) {
      return this.frTagList.find(tag => tag.id === id).locked === 1
    },
    handleTagOptions() {
      this.showTagOptions = !this.showTagOptions
    },
    addTag(id) {
      let temp = [...this.selectedTags]
      temp.push(id)
      this.selectedTags = temp
    },
    removeTag(index) {
      let temp = [...this.selectedTags]
      temp.splice(index, 1)
      this.selectedTags = temp
    },
    onClickOutside() {
      this.showTagOptions = false
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.fr-tags {
  width: 100%;
  height: 100%;
  padding: 12px 0px 12px 12px;

  .title {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;
    color: #FFE99F;
    margin-bottom: 6px;
    img {
      margin-right: 8px;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 36px);
    overflow-y: overlay;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 8px;
    padding-right: 8px;
    box-sizing: border-box;

    .tag {
      display: flex;
      align-items: center;
      height: 36px;
      font-size: 24px;
      line-height: 20px;
      color: #ffffff;
      background: #ffffff33;
      border-radius: 18px;
      padding: 0 12px 1px 12px;
    }
  }
}

.fr-tags-edit {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
  
  .title {
    width: 150px;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: normal;
    line-height: 36px;
    color: #ffffff;
    background: #282942;
    border: 1px solid #9D9D9D;
    border-radius: 8px 0px 0px 8px;
    padding: 0 10px;
    img {
      margin-right: 8px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(14%) saturate(7493%) hue-rotate(180deg) brightness(115%) contrast(92%);
    }
  }

  .content {
    flex: 1;
    display: flex;
    overflow-y: overlay;
    padding: 12px 8px 4px 12px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 0px 8px 8px 0px;
    
    .tags {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      column-gap: 10px;
      row-gap: 8px;
      
      .tag {
        display: flex;
        align-items: center;
        font-size: 24px;
        height: 36px;
        line-height: 28px;
        color: #ffffff;
        background: #4A5C78;
        border-radius: 18px;
        padding-right: 12px;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background: #6E7D93;
          margin-right: 8px;
          cursor: pointer;
          img {
            width: 20px;
            height: 20px;
          }
        }
        .square {
          width: 12px;
          height: 12px;
          background: #F94144;
          margin-right: 8px;
        }
      }
    }

    .add-tag {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background: #282942;
      margin-left: 20px;
      margin-right: 4px;
      cursor: pointer;
    }
  }

  .tag-options {
    position: absolute;
    top: 52px;
    right: -110px;
    width: 280px;
    max-height: 35vh;
    padding: 10px 0;
    overflow: overlay;
    display: flex;
    flex-direction: column;
    background: #151B35;
    border: 1px solid #4A5C78;
    border-radius: 5px;
    z-index: 10;
    .tag {
      padding: 8px 13px;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 1.2px;
      color: #ffffff;
      text-align: center;
      position: relative;
      cursor: pointer;
      &:hover {
        background: #4A5C78;
      }
      .locked {
        position: absolute;
        left: 0px;
        top: 8px;
        width: 6px;
        height: 32px;
        background: #F94144;
        border-radius: 0px 4px 4px 0px;
      }
    }
  }
}
</style>